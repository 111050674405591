<script setup lang="ts">
const authStore = useAuthStore()
authStore.init()

const seoStore = useSeoStore()

watch([
	() => seoStore.title,
	() => seoStore.description,
	() => seoStore.keywords
], () => {
	useSeoMeta({
		title: seoStore.title,
		description: seoStore.description,
		keywords: seoStore.keywords
	})
})

</script>

<template>
  <LayoutTop />
  
  <main
    role="main"
    class="flex flex-1 justify-center items-center w-full max-md:max-w-full"
  >
    <div class="w-full h-full">
	    <slot />
    </div>
  </main>
  
  <LayoutBottom />
	
	<UNotifications />
</template>